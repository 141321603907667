/*
 * @Author: dongjia
 * @Date: 2021-09-08 14:21:43
 * @LastEditTime: 2021-09-08 14:22:45
 * @LastEditors: aleaner
 * @Description: 积分用户记录
 * @FilePath: \incomes\api\income-records.js
 * 怎么可能会有bug！！！
 */
import api from "@/base/utils/request";

// 获取用户记录列表数据
export const recordList = data => {
  return api({
    url: "/admin/incomes/record/index",
    method: "post",
    data
  });
};