<!--
 * @Author: dongjia
 * @Date: 2021-09-08 14:17:47
 * @LastEditTime: 2021-09-08 14:39:46
 * @LastEditors: aleaner
 * @Description: 用户记录
 * @FilePath: \incomes\views\income-records.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="list-page">
    <IncomeRecordsFilter :filterForm="filterForm" :uploadFilter="ok" />
    <div class="list-content" style="margin-top: 20px" v-loading="tableLoading">
      <el-table :data="tbody" style="width: 100%" class="thead-light" stripe>
        <el-table-column show-overflow-tooltip v-for="th in thead" :key="th.prop" :label="th.label"
          :prop="th.prop" :width="th.width|| ''" :min-width="th.minWidth || ''" :fixed="th.fixed">
          <template slot-scope="{row}">
            <!-- 其他 -->
            <span>{{row[th.prop] | placeholder}}</span>
          </template>
        </el-table-column>
      </el-table>
      <Pagination :total="pageData.total" :page="pageData.current_page" :change="changPage" />
    </div>
  </div>
</template>

<script>
import Pagination from "@/base/components/Default/Pagination";
import IncomeRecordsFilter from "../components/IncomeRecordsFilter";
import { recordList } from "../api/income-records";

export default {
  data() {
    return {
      tableLoading: false, //表格加载中
      thead: [
        {
          label: "创建时间",
          prop: "create_time",
          minWidth: 160,
          sortable: true,
        },
        { label: "行为", prop: "behavior", minWidth: 110 },
        { label: "经验", prop: "experience", minWidth: 100 },
        { label: "积分", prop: "integral", minWidth: 80 },
      ],
      tbody: [],
      filterForm: {
        page_size: 15,
        uid: "",
        start_time: -1,
        end_time: -1,
      },
      pageData: {},
    };
  },
  methods: {
    //获取数据列表
    getRecordList(requestData) {
      this.tableLoading = true;
      this.pageData = {};
      recordList(requestData)
        .then((res) => {
          const { data } = res;
          this.tbody = data.data;
          this.pageData = data;
          this.tableLoading = false;
        })
        .catch((err) => {
          this.tableLoading = false;
        });
    },
    //表格排序
    sortChange(column) {
      this.filterForm.is_desc = !column.order
        ? -1
        : column.order === "ascending"
        ? 0
        : 1;
      this.filterForm.order_by = column.prop;
      this.getRecordList(this.filterForm);
    },
    // 分页查询
    changPage(e) {
      this.filterForm.page_size = e.page_size;
      const data = { ...this.filterForm, ...e };
      this.getRecordList(data);
    },
    // 筛选回调
    ok(e) {
      this.filterForm = { ...this.filterForm, ...e, page: 1 };
      this.getRecordList(this.filterForm);
    },
  },
  created() {
    //获取数据列表
    this.getRecordList(this.filterForm);
  },
  components: {
    Pagination,
    IncomeRecordsFilter,
  },
};
</script>

<style lang="scss" scoped>
</style>